import { FC, useState } from "react";
import { FormWrapper } from "../../../Common/FormWrapper";
import {
  CreateLoanProlongationDto,
  LoanDto,
  LoanProlongationOrigin,
  ProlongationPreviewDto,
} from "../../../../types/api";
import { Box, TextField, Typography } from "@mui/material";
import { formatDate, formatMoney } from "../../../../utils/format";
import { useLoading } from "../../../../context/loading-context";
import axios from "../../../../utils/axios";
import { parseApiError } from "../../../../utils/utils";
import { useAppDispatch } from "../../../../selectors/selectors";
import { addSnackbar } from "../../../../store/slices/snackbarsSlice";
import Loading from "../../../UI/Loading";
import { useTranslation } from "react-i18next";

interface IProlongationRequestFormProps {
  loan: LoanDto;
  prolongationPreview: ProlongationPreviewDto | null;
  next: () => void;
}

export const ProlongationRequestForm: FC<IProlongationRequestFormProps> = ({
  loan,
  prolongationPreview,
  next,
}) => {
  const [formInput, setFormInput] = useState("");
  const { loading, setLoading } = useLoading();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onSubmit = async () => {
    setLoading(true);
    try {
      const submitData: CreateLoanProlongationDto = {
        loanId: loan.id ?? "",
        prolongationReason: formInput,
        internalNote: "",
        origin: LoanProlongationOrigin.MANUAL,
      };

      await axios.post(`loans/${loan.id}/prolongations`, submitData);

      next();
    } catch (error) {
      const { message } = parseApiError(error);
      dispatch(addSnackbar({ message, variant: "error" }));
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormInput(event.target.value);
  };

  return (
    <FormWrapper
      onSubmit={onSubmit}
      currentStep={1}
      title={t("prolongation-form-title")}
    >
      <Typography variant="h6" fontWeight="700">
        {t("prolongation-form-conditions")}
      </Typography>
      <Typography>
        {t("prolongation-form-payment-1")}{" "}
        <Typography component="span" color="primary" sx={{ fontWeight: "700" }}>
          {formatMoney(
            prolongationPreview?.prolongationFeeTotal,
            loan?.currency
          )}
        </Typography>{" "}
        - {t("prolongation-form-payment-2")}
      </Typography>
      <br />
      <Typography variant="h6" fontWeight="700">
        {t("prolongation-form-installment-preview")}
      </Typography>
      <Box
        sx={{
          maxHeight: "8.5rem",
          overflowY: "auto",
          padding: "0 1rem",
          marginTop: "1rem",
          marginLeft: "0.5rem",
        }}
      >
        {prolongationPreview?.installments?.map((installment, index) => {
          const { amount, dueDate, paidDate } = installment;

          let content;
          if (paidDate) {
            content = (
              <>
                {formatMoney(amount, loan.currency)}{" "}
                {t("prolongation-form-due-date")} {formatDate(dueDate)}{" "}
                {t("prolongation-form-paid-date")} {formatDate(paidDate)}
              </>
            );
          } else if (amount === prolongationPreview.prolongationFeeTotal) {
            content = (
              <>
                <Typography component="span" color="primary" fontWeight="bold">
                  {formatMoney(amount, loan.currency)}
                </Typography>{" "}
                {t("prolongation-form-fee")}{" "}
                <Typography
                  component="span"
                  color="secondary"
                  fontWeight="bold"
                >
                  {t("prolongation-form-fee-not-paid")}
                </Typography>
              </>
            );
          } else {
            content = (
              <>
                <Typography component="span" color="primary" fontWeight="bold">
                  {formatMoney(amount, loan.currency)}
                </Typography>{" "}
                {t("prolongation-form-due-date")}{" "}
                <Typography
                  component="span"
                  color="secondary"
                  fontWeight="bold"
                >
                  {formatDate(dueDate)}
                </Typography>
              </>
            );
          }

          return (
            <Typography
              key={index}
              sx={{
                marginBottom: "1rem",
                "&:last-child": { marginBottom: 0 },
                height: "1rem",
              }}
            >
              <Box
                component="span"
                sx={{
                  fontWeight: "bold",
                  marginRight: "0.5rem",
                  overflowY: "scroll",
                  scrollbarGutter: "stable",
                }}
              >
                {index + 1}.
              </Box>
              {content}
            </Typography>
          );
        })}
      </Box>

      <Typography
        variant="h5"
        fontWeight="700"
        sx={{ margin: "2rem 0 1rem 0" }}
      >
        {t("prolongation-form-reason")}
      </Typography>
      <TextField
        multiline
        rows={2}
        fullWidth
        variant="outlined"
        value={formInput}
        onChange={handleInputChange}
        placeholder={t("prolongation-form-reason-prompt")}
        required
      />
      {loading && <Loading isLoading={loading} />}
    </FormWrapper>
  );
};
